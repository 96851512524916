<!-- Page Content Holder -->
<div id="content" class="StoltBGDefault" #cargoClass>
    <div  #filterClass class="StoltCommonContentArea">
        <div class="booking-heading">
            <div class="booking-heading-left"> 
                <snl-page-title>
                    <div title-content>
                        <span class="header-h1">Berth Schedule</span>
                    </div>
                </snl-page-title>

                <div class="HeaderCommonTextMain">   
                    <div class="HeaderCommonText body-b1">
                        <p>Note: Berth schedules displayed are in local time and are subject to change.</p>
                    </div>
                    <div class="LegendsBox caption"> 
                        <span>Legends:</span>
                        <div class="LegendsIconsHld">
                            <div class="indicatorItem">
                                <svg-icon src="/assets/icons/indicator-line-eta.svg"></svg-icon>
                                <span>ETA</span>
                            </div>
                            <div class="indicatorItem">
                                <svg-icon src="/assets/icons/indicator-line-etd.svg"></svg-icon>
                                <span>ETD</span>
                            </div>
                            <div class="indicatorItem">
                                <svg-icon src="/assets/icons/currentdayline.svg"></svg-icon>
                                <span>Current day and time</span>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        <!-- Page Content Card -->
        <div class="CommonCardContent">
            <div class="ETB__UpdatesWrap CalendarPreviewWrp" (mousemove)="onMouseMove($event)">
               
                <div *ngIf="!isShow" class="Stolt-Skeleton Skeleton-Calendar"></div>
                <full-calendar [ngClass]="{'hide': (!isShow || !customEvent.length)}" *ngIf='calendarVisible' [options]='calendarOptions'>
                    <ng-template #eventContent let-arg>
                      <i>
                        <em>{{ arg.event.title }}</em>
                          <span *ngIf="tooltipData && tooltipTitle && tooltipTitle == arg.event.title">
                            <snl-tooltip [position]="'top'" class="TttoltipCleInner">
                                <span class="section-title HideHeadingMn">Legends</span>
                                <div tooltipContent class="snl-tooltip-custmzd"> 
                                  <div class="body-b"> 
                                        <div class="HeaderCdata" [ngClass]="{'mb-0': tooltipTitle == 'Next berth' || tooltipTitle == 'Prior berth'}">
                                            <span class="body-b2">{{tooltipData?.vesselVoyNo | titlecase}}</span>
                                            <span class="sub-heading-s1">{{tooltipTitle}}</span>
                                        </div>

                                        <div class="tooltipInbdy"  *ngIf="tooltipTitle != 'Next berth' && tooltipTitle != 'Prior berth'">
                                              <div class="BodyInnerCl">
                                                    <div class="optionsInn">
                                                        <span class="InnerOptTitle body-b">
                                                            ETA/ATA
                                                        </span>
                                                        <span class="section-title">
                                                            <ng-container *ngIf="tooltipData.actualDateArival && tooltipData.estimatedDateArival; else checkFirst">
                                                                {{ tooltipData.actualDateArival | date: 'd MMM yyyy, HH:mm' : 'UTC' }} (ATA)
                                                              </ng-container>
                                                              <ng-template #checkFirst>
                                                                <ng-container *ngIf="tooltipData.actualDateArival && !tooltipData.estimatedDateArival; else checkSecond">
                                                                    {{ tooltipData.actualDateArival | date: 'd MMM yyyy, HH:mm' : 'UTC' }} (ATA)
                                                                </ng-container>
                                                                <ng-template #checkSecond>
                                                                  <ng-container *ngIf="!tooltipData.actualDateArival && tooltipData.estimatedDateArival; else noTbc">
                                                                    {{ tooltipData.estimatedDateArival | date: 'd MMM yyyy, HH:mm' : 'UTC' }}
                                                                  </ng-container>
                                                                </ng-template>
                                                              </ng-template>
                                                              <ng-template #noTbc>
                                                                TBD
                                                              </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="optionsInn">
                                                        <span class="InnerOptTitle body-b">
                                                            ETD/ATD
                                                        </span>
                                                        <span class="section-title">
                                                            <ng-container *ngIf="tooltipData.actualDateDeparture && tooltipData.estimatedDateDeparture; else checkDep1">
                                                                {{ tooltipData.actualDateDeparture | date: 'd MMM yyyy, HH:mm' : 'UTC' }} (ATD)
                                                              </ng-container>
                                                              <ng-template #checkDep1>
                                                                <ng-container *ngIf="tooltipData.actualDateDeparture && !tooltipData.estimatedDateDeparture; else checkDep2">
                                                                    {{ tooltipData.actualDateDeparture | date: 'd MMM yyyy, HH:mm' : 'UTC' }} (ATD)
                                                                </ng-container>
                                                                <ng-template #checkDep2>
                                                                  <ng-container *ngIf="!tooltipData.actualDateDeparture && tooltipData.estimatedDateDeparture; else tbcDep2">
                                                                    {{ tooltipData.estimatedDateDeparture | date: 'd MMM yyyy, HH:mm' : 'UTC' }}
                                                                  </ng-container>
                                                                </ng-template>
                                                              </ng-template>
                                                              <ng-template #tbcDep2>
                                                                TBD
                                                              </ng-template>
                                                        </span>
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                                </div>
                              </snl-tooltip>
                        </span>
                      </i>
                    </ng-template>
                </full-calendar>
                <div *ngIf="!isScroll" class="Stolt-Skeleton Skeleton-Calendar"></div>
                <div *ngIf="!customEvent.length && !isFilterSkeleton" class="DataNotAvailable BirthShedDataNotAvai"><span>No data found.</span></div>
                <span [ngStyle]="{
                  'left.px': cursorX,
                  'top.px': cursorY,
                }" *ngIf="isCurrentTime" class="TodayDateTooltip">Today, {{ todayDate | date: 'dd MMM yyyy' }} - {{ todayDate | date: 'HH:mm' }}</span> 
            </div>
        </div>
    </div> 
</div>